import React, {useState, useEffect, useRef, useLayoutEffect, useCallback} from 'react';
import {
    ArrowLeft,
    ArrowRight,
    ChevronDown,
    ChevronLeft,
    ChevronsDown, ChevronUp,
    CornerDownLeft, Maximize2,
    MessageCircle, Minimize2, Minus, Plus,
    Send,
    X
} from 'react-feather';
import './conversation2.css';
import { ObjectID } from 'bson'


const Conversation2 = ({
                           messages: initialMessages = [],
                           currentUser,
                           onSendMessage,
                           placeholder = "typeAMessage",
                           maxHeight = "440px",
                           minHeight = "60px",
                           t
                       }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [replyMessage, setReplyMessage] = useState(null);
    const [messageDiscussion, setMessageDiscussion] = useState(null); // State for toggling views //showDiscussion setShowDiscussion
    const [activeMessageIndex, setActiveMessageIndex] = useState(0);
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);
    const messagesEndRef = useRef(null);
    const specificMessageRef = useRef(null); // Ref for the specific message
    const [lastViewedMessageId, setLastViewedMessageId] = useState(null);  // Store message ID instead of DOM reference
    const shouldScrollRef = useRef(false);  // Flag to control when to scroll
    const [isMinimized, setIsMinimized] = useState(false); // Minimize state




    // Initialize messages with initialMessages prop
    useEffect(() => {
        setMessages(initialMessages);
    }, [initialMessages]);


    /*
    useEffect(() => {
        if (messagesEndRef.current) {
            // Always scroll to the last message on initial load or when messages change
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [messages]);


    useEffect(() => {
        // Scroll immediately on component mount
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, []);
     */


    // Scroll to the bottom whenever messages are updated
    useEffect(() => {
        if (messagesEndRef.current) {
            const isAtBottom = messagesEndRef.current.getBoundingClientRect().top <= window.innerHeight;
            if (isAtBottom) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [messages]);

    // Function to scroll to the last message
    const scrollToLastMessage = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSend = () => {
        if (newMessage.trim()) {
            const OID = new ObjectID()
            const newMessageObject = {
                id: OID.toString(),
                text: newMessage,
                sender: currentUser,
                responseTo: replyMessage,
                parentMessage: messageDiscussion,
                timestamp: new Date().toISOString(),
            };
            setMessages([...messages, newMessageObject]);
            onSendMessage(newMessageObject); // Call the onSendMessage action
            setNewMessage('');
            setReplyMessage(null); // Clear the reply message after sending
        }
    };

    const handleReply = (replyMessage) => {
        setReplyMessage(replyMessage); // Set the message text being replied to
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleStartDiscussion = (msg) => {
        setMessageDiscussion(msg)
        setReplyMessage(null);
        // Save current scroll position
        setLastViewedMessageId(msg.id);  // Store the ID of the message
    }

    const handleBackFromDiscussion = () => {
        setMessageDiscussion(null);
        setReplyMessage(null);
        shouldScrollRef.current = true;  // Set flag to true before state updates
    }

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    // Single effect to handle scrolling when returning from discussion
    useLayoutEffect(() => {
        if (shouldScrollRef.current && !messageDiscussion && lastViewedMessageId) {
            const element = document.getElementById(`message-${lastViewedMessageId}`);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
                shouldScrollRef.current = false;  // Reset the flag
            }
        }
    }, [messageDiscussion, lastViewedMessageId]);

    // Single effect to handle scrolling when returning from discussion
    const scrollToAMessage = (msgID) => {
        if (msgID) {
            const element = document.getElementById(`message-${msgID}`);
            if (element) {
                // Highlight the message
                setHighlightedMessageId(msgID);

                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest', // Align to the center of the view for better visibility
                });



                /*// Remove highlight after a short delay
                // Add the highlight class
                element.classList.add('highlighted');

                // Remove the highlight class after a delay using CSS transitions
                setTimeout(() => {
                    element.classList.remove('highlighted');
                }, 500); // Match the CSS transition duration */


                //setTimeout(() => {
                //    setHighlightedMessageId(null);
                //}, 500);
            }
        }
    };

    const getSortedUniqueParentMessages = (messages) => {
        const uniqueParentMessages = _.uniqBy(
            messages.map((msg) => msg.parentMessage).filter((parentMsg) => parentMsg !== null),
            'id'
        );

        return _.sortBy(uniqueParentMessages, (parentMsg) => new Date(parentMsg.timestamp)).reverse()
    };

    const sortedUniqueParentMessages = getSortedUniqueParentMessages(messages);
    console.log('sortedUniqueParentMessages', sortedUniqueParentMessages)

    const navigateToPreviousMessage = () => {
        if (sortedUniqueParentMessages.length > 0){
            if (activeMessageIndex <= sortedUniqueParentMessages.length) { //initialization
                const previousIndex = activeMessageIndex + 1;
                setActiveMessageIndex(previousIndex);
                scrollToAMessage(sortedUniqueParentMessages[previousIndex]?.id);
            }
        }
        console.log("activeMessageIndex1", activeMessageIndex)
    };

    const navigateToNextMessage = () => {
        if (sortedUniqueParentMessages.length > 0){
            if (activeMessageIndex > 0) {
                const nextIndex = activeMessageIndex - 1;
                setActiveMessageIndex(nextIndex);
                scrollToAMessage(sortedUniqueParentMessages[nextIndex]?.id);
            }
            else { // activeMessageIndex = 0
                setActiveMessageIndex(activeMessageIndex);
                scrollToAMessage(sortedUniqueParentMessages[activeMessageIndex]?.id);
            }
        }
        console.log("activeMessageIndex2", activeMessageIndex)
    };



    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const x = messageDiscussion && messages.filter(msg => msg.parentMessage?.id === messageDiscussion.id || msg.id === messageDiscussion.id)

    const mainMessages = messages.filter(msg => !msg.parentMessage)

    const mainMessagesGroupedByDay = _.groupBy(mainMessages, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });

    const subMessagesOfAMainMessage = !messageDiscussion ? [] : messages.filter(msg => msg.parentMessage?.id === messageDiscussion.id || msg.id === messageDiscussion.id)
    const subMessagesGroupedByDay = _.groupBy(subMessagesOfAMainMessage, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });

    return (
        <div className="conversation-container">
            {!messageDiscussion ? (
                <>
                    <div
                        className="conversation-messages"
                        style={{
                            maxHeight: maxHeight,
                            height: isMinimized ? minHeight : maxHeight,
                            transition: 'height 0.5s ease', // Smooth height transition
                            willChange: 'height', // Add this
                        }}
                    >
                        {mainMessages.length === 0 ? (
                                <div className="no-messages-placeholder">
                                    <p
                                        className="no-messages-placeholder"
                                    >
                                        Démarrer la discussion...
                                    </p>
                                </div>
                            ) :
                            (
                                Object.keys(mainMessagesGroupedByDay).map((date) => (
                                    <div key={date} className="grouped-messages">
                                        {/* Date Header */}
                                        <div className="message-date">{date}</div>
                                        {/* Messages */}
                                        {mainMessagesGroupedByDay[date].map((message) => (
                                            <div
                                                key={message.id}
                                                id={`message-${message.id}`}
                                                className={`message-container ${message.sender.id === currentUser.id ? 'sent' : 'received'} ${
                                                    highlightedMessageId === message.id ? 'highlighted' : ''
                                                }`}
                                            >
                                                <div
                                                    className={`message-bubble ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                                >
                                                    <div className="message-header">
                                                        <span className="sender-name">{message.sender.name}</span>
                                                        <CornerDownLeft
                                                            className="replyIcon"
                                                            size={18}
                                                            onClick={() => handleReply(message)}
                                                        />
                                                    </div>
                                                    {message.responseTo && (
                                                        <div
                                                            className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                                        >
                                                            <span
                                                                className="reply-sender-name">{message.responseTo.sender.name}</span>
                                                            <p className="message-text">{message.responseTo.text}</p>
                                                        </div>
                                                    )}
                                                    <p className="message-text">{message.text}</p>
                                                    <span
                                                        className="message-time">{formatTime(message.timestamp)}</span>
                                                    <button
                                                        onClick={() => handleStartDiscussion(message)}
                                                        className="leaveCommentButton"
                                                    >
                                                        <div className="icon-with-badge">
                                                            <MessageCircle className="icon-left" size={18}/>
                                                            {
                                                                messages.filter(msg => msg.parentMessage?.id === message.id || msg.id === message.id).length - 1 > 0 &&
                                                                (<span className="badge">
                                                                    {messages.filter(msg => msg.parentMessage?.id === message.id || msg.id === message.id).length - 1}
                                                                </span>)
                                                            }
                                                        </div>
                                                        <span className="button-text">Ouvrir la discussion</span>
                                                        <ArrowRight className="icon-right" size={18}/>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            )
                        }
                        {/* Reference to the bottom of the message list */}
                        <div ref={messagesEndRef}/>
                    </div>
                    <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"} onClick={toggleMinimize}>
                        {isMinimized ? <Maximize2 size={20} style={{flexShrink: 0}}/> : <Minimize2 size={20} style={{flexShrink: 0}}/>}
                    </button>
                    {!isMinimized && [
                        <div className="test">
                            <button className="goUpButton" title="Naviguer vers la discussion active précedente" onClick={navigateToPreviousMessage}>
                                <ChevronUp size={22} style={{flexShrink: 0}}/>
                            </button>
                            <div className="activeMessagesNavigationBadge">
                                {`${sortedUniqueParentMessages.length - activeMessageIndex}/${sortedUniqueParentMessages.length}`}
                            </div>
                            < button className="goDownButton" title="Naviguer vers la discussion active suivante" onClick={navigateToNextMessage}>
                                <ChevronDown size={22} style={{flexShrink: 0}}/>
                            </button>
                        </div>,
                        <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion" onClick={scrollToLastMessage}>
                            <ChevronsDown size={24} style={{flexShrink: 0}}/>
                        </button>
                    ]}
                    {/* Reply Preview Above Input */}
                    <div className="message-input-container">
                        {replyMessage && (
                            <div className="reply-preview">
                                <button className="replyIcon2"><CornerDownLeft size={18}
                                                                               style={{cursor: "default"}}/>
                                </button>
                                <div
                                    className={`replyMessage-header sent`}
                                >
                                    <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                    <p className="message-text">{replyMessage.text}</p>
                                </div>
                                <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                    <X size={18}/>
                                </button>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        <div className="rawInput">
                            <textarea
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder={t(placeholder)}
                                className="message-input"
                                rows={2}
                            />
                            <button
                                onClick={handleSend}
                                disabled={!newMessage.trim()}
                                className="send-button"
                            >
                                <Send size={18}/>
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                /* New Discussion View */

                <>
                    <div className="backButtonContainer">
                        <button
                                onClick={() => handleBackFromDiscussion()}
                                className={`back-button expanding`}
                            >
                                <ChevronLeft className="icon-left" size={30} style={{flexShrink: 0, marginLeft: "-4px"}}/>
                        </button>
                    </div>
                    <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"} onClick={toggleMinimize}>
                        {isMinimized ? <Maximize2 size={20} style={{flexShrink: 0}}/> : <Minimize2 size={20} style={{flexShrink: 0}}/>}
                    </button>
                    {!isMinimized &&
                        <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion" onClick={scrollToLastMessage}>
                            <ChevronsDown size={24} style={{flexShrink: 0}}/>
                        </button>
                    }
                    <div className="conversation-subMessages"
                         style={{
                             maxHeight: maxHeight,
                             height: isMinimized ? minHeight : maxHeight,
                             transition: 'height 0.1s ease', // Smooth height transition
                             willChange: 'height', // Add this
                         }}
                    >
                    {
                            Object.keys(subMessagesGroupedByDay).map((date) => (
                                <div key={date} className="grouped-messages">
                                    {/* Date Header */}
                                    <div className="message-date"><span>{date}</span></div>
                                    {/* Messages */}
                                    {subMessagesGroupedByDay[date].map((message) => (
                                        <div
                                            key={message.id}
                                            className={`message-container ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                        >
                                            <div
                                                className={`message-bubble ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                                <div className="message-header">
                                                    <span className="sender-name">{message.sender.name}</span>
                                                    <CornerDownLeft
                                                        className="replyIcon"
                                                        size={18}
                                                        onClick={() => handleReply(message)} // Set the reply message text
                                                    />
                                                </div>
                                                {message.responseTo && (
                                                    <div
                                                        className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                            <span
                                                className="reply-sender-name">{message.responseTo.sender.name}</span>
                                                        <p className="message-text">
                                                            {message.responseTo.text}
                                                        </p>
                                                    </div>
                                                )}
                                                <p className="message-text">{message.text}</p>
                                                <span className="message-time">{formatTime(message.timestamp)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                        {/* Reference to the bottom of the message list */}
                        <div ref={messagesEndRef}/>
                    </div>
                    {/* Reply Preview Above Input */}
                    <div className="message-input-container">
                        {replyMessage && (
                            <div className="reply-preview">
                                <button className="replyIcon2">
                                    <CornerDownLeft size={18} style={{cursor: "default"}}/>
                                </button>
                                <div
                                    className={`replyMessage-header sent`}
                                >
                                    <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                    <p className="message-text">{replyMessage.text}</p>
                                </div>
                                <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                    <X size={18}/>
                                </button>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        <div className="rawInput">
                                    <textarea
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder={t(placeholder)}
                                        className="message-input"
                                        rows={2}
                                    />
                            <button
                                onClick={handleSend}
                                disabled={!newMessage.trim()}
                                className="send-button"
                            >
                                <Send size={18}/>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Conversation2;