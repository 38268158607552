import React from 'react'
import {Field, FieldArray} from 'redux-form'
import { I18n } from 'react-i18next'
import {getModule, getUserIdAndName} from '../../selectors'
import {connect} from 'react-redux'
import Conversation2 from "../../../../components/conversation2";

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const VisualComponent = ({
                             fields: { push, getAll },
                             editable,
                             disabled,
                             placeholder,
                             module,
                             user
                         }) => {
    const objects = getAll() || [];
    const initialMessages = objects

    const handleSendMessage = (newMessage) => {
        push(newMessage)
    }

    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <Conversation2
                    messages={initialMessages}
                    currentUser={user}
                    onSendMessage={handleSendMessage}
                    placeholder={placeholder}
                    t={t}
                    disabled={disabled}
                    editable={editable}
                />
            )}
        </I18n>
    )
}

const ConversationField = ({ field, module, user, objectMode }) => {
    return (
        <FieldArray
            name={field.path}
            placeholder={field.placeholder}
            component={VisualComponent}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable}
            module={module}
            user={user}
            rerenderOnEveryChange={true}
        />
    )
}

const mapStateToProps = (state) => ({
    module: getModule(state),
    user: getUserIdAndName(state),
})

export default connect(mapStateToProps)(ConversationField)